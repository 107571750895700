export default defineNuxtPlugin(() => {
  const { $config } = useNuxtApp()
  const router = useRouter()

  if (+$config.MAINTAIN === 1) {
    addRouteMiddleware((to) => {
      if (to.path !== '/maintenance') {
        return router.push('/maintenance')
      }
    })
  }
})
