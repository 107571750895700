import { ERROR } from '~/constants/common'

export default defineNuxtPlugin((nuxtApp) => {
  const { $axios, $ua, ssrContext, $config } = nuxtApp as Record<string, any>
  if ($axios && $ua) {
    $axios.defaults.withCredentials = true
    $axios.setHeader('os', $ua.os())
    $axios.setHeader('device', $ua.deviceType())
    $axios.setHeader('browser', $ua.browser())
  }

  const headers = useRequestHeaders()
  const host = headers.host
  const promotionBaseURL = ssrContext?.runtimeConfig?.API_PROMOTION_BASE_URL
  const defaultBaseURL = ssrContext?.runtimeConfig?.API_BASE_URL

  if (ssrContext) {
    $axios.setBaseURL(defaultBaseURL)
  } else {
    $axios.setBaseURL(window.location.origin)
  }

  $axios.onRequest((config: any) => {
    if (ssrContext) {
      const cookies = (config.headers.common.cookie?.split(';') ?? []).map((cookie: string) => cookie.trim())
      const _idxBrandCode = cookies?.findIndex((cookie: string) => cookie.startsWith('brand_code=')) ?? -1
      if (_idxBrandCode > -1) {
        cookies[_idxBrandCode] = `brand_code=${ssrContext?.runtimeConfig?.public?.BRAND_CODE}`
      } else {
        cookies?.push?.(`brand_code=${ssrContext?.runtimeConfig?.public?.BRAND_CODE}`)
      }

      // Add host to cookie
      const _idxHost = cookies?.findIndex((cookie: string) => cookie.startsWith('host=')) ?? -1
      if (_idxHost > -1) {
        cookies[_idxHost] = `host=${host}`
      } else {
        cookies?.push?.(`host=${host}`)
      }
      const device = $ua.getDevice()
      if (device) {
        cookies?.push?.(`device=${device}`)
      }
      config.headers.common.cookie = cookies.join('; ')
    } else {
      const cookie = useCookie('brand_code', {
        path: '/',
        maxAge: 60 * 60 * 24 * 10
      })
      !cookie.value && (cookie.value = $config.public?.BRAND_CODE)

      // Add host to cookie
      const hostCookie = useCookie('host', {
        path: '/',
        maxAge: 60 * 60 * 24 * 10
      })
      !hostCookie.value && (hostCookie.value = host)
    }
    if (config.url?.startsWith('/api-promotion/')) {
      config.baseURL = promotionBaseURL
    } else if (config.url?.startsWith('/api/')) {
      config.baseURL = defaultBaseURL
    }
    return config
  })

  $axios.interceptors.response.use(
    function (response: { config: { headers: any; url: any } }) {
      // Just log on server
      if (ssrContext) {
        const headers = response.config.headers

        const requestInfor = {
          IP: headers['x-forwarded-for'] || 'IP cannot detected',
          country: headers['cf-ipcountry'] || 'country cannot detected',
          os: headers.os,
          device: headers.device,
          browser: headers.browser
        }

        console.log(
          `${response.config.url} - ${requestInfor.country} - ${requestInfor.IP} - ${requestInfor.device} - ${requestInfor.os} - ${requestInfor.browser}`
        )
      }
      return response
    },
    function (error: { config: { bypassErrorToast: any }; response: { data: { message: any } } }) {
      if (!ssrContext) {
        const nuxtApp = useNuxtApp()
        if (!error?.config.bypassErrorToast) {
          nuxtApp.$alertMsg('error', error?.response?.data?.message || ERROR)
        }
      }
      throw error
    }
  )
})
