import dayjs from 'dayjs'
import QrcodeVue from 'qrcode.vue'
import BaseImg from '~/components/common/base-img.vue'

export default defineNuxtPlugin((nuxtApp) => {
  // Custom Vue directives
  nuxtApp.vueApp.directive('click-outside', {
    beforeMount(el, binding) {
      const clickHandler = (event: any) => {
        if (!el.contains(event.target) && el !== event.target) {
          binding.value(event)
        }
      }
      el.__vueClickEventHandler__ = clickHandler
      document.addEventListener('click', clickHandler)
    },
    unmounted(el) {
      document.removeEventListener('click', el.__vueClickEventHandler__)
    }
  })

  // Global component registration
  nuxtApp.vueApp.component('BaseImg', BaseImg)
  nuxtApp.vueApp.component('QrcodeVue', QrcodeVue)
  // Utility functions
  const normalizeDiacritics = (str: string): string => {
    const accentMap: { [key: string]: string } = {
      à: 'a',
      á: 'a',
      ạ: 'a',
      ả: 'a',
      ã: 'a',
      â: 'a',
      ầ: 'a',
      ấ: 'a',
      ậ: 'a',
      ẩ: 'a',
      ẫ: 'a',
      ă: 'a',
      ằ: 'a',
      ắ: 'a',
      ặ: 'a',
      ẳ: 'a',
      ẵ: 'a',
      è: 'e',
      é: 'e',
      ẹ: 'e',
      ẻ: 'e',
      ẽ: 'e',
      ê: 'e',
      ề: 'e',
      ế: 'e',
      ệ: 'e',
      ể: 'e',
      ễ: 'e',
      ì: 'i',
      í: 'i',
      ị: 'i',
      ỉ: 'i',
      ĩ: 'i',
      ò: 'o',
      ó: 'o',
      ọ: 'o',
      ỏ: 'o',
      õ: 'o',
      ô: 'o',
      ồ: 'o',
      ố: 'o',
      ộ: 'o',
      ổ: 'o',
      ỗ: 'o',
      ơ: 'o',
      ờ: 'o',
      ớ: 'o',
      ợ: 'o',
      ở: 'o',
      ỡ: 'o',
      ù: 'u',
      ú: 'u',
      ụ: 'u',
      ủ: 'u',
      ũ: 'u',
      ư: 'u',
      ừ: 'u',
      ứ: 'u',
      ự: 'u',
      ử: 'u',
      ữ: 'u',
      ỳ: 'y',
      ý: 'y',
      ỵ: 'y',
      ỷ: 'y',
      ỹ: 'y',
      đ: 'd'
    }
    str = str.toLowerCase().trim().replace(/\s\s+/g, ' ')
    return str
      .replace(/[^a-z0-9]/g, (char) => accentMap[char] || char)
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
  }

  const updateQueryStringParameter = (uri: string, key: string, value: string): string => {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i')
    const separator = uri.includes('?') ? '&' : '?'
    return uri.match(re) ? uri.replace(re, `$1${key}=${value}$2`) : `${uri}${separator}${key}=${value}`
  }

  const formatPhone = (phone: string): string => {
    const cleaned = phone.replace(/\D/g, '')
    const match =
      cleaned.length === 10 ? cleaned.match(/^(\d{4})(\d{3})(\d{3})$/) : cleaned.match(/^(\d{5})(\d{3})(\d{3})$/)
    return match ? `${match[1]} ${match[2]} ${match[3]}` : ''
  }

  const formatNumber = (num: number | string, places: number = 0): string => Number(num).toFixed(places)

  const formatMoney = (value: number | string, multiplier: number = 1): string => {
    const amount = typeof value === 'string' ? Number(value.replace(/,|\./g, '')) * multiplier : value
    return amount.toLocaleString('vi-VN')
  }

  const formatMarkToNumber = (str: string, multiplier = 1): number => {
    if (str) {
      return Math.round(Number(str.replace(/,|\./g, '')) * multiplier)
    }
    return 0
  }

  const formatNumberWithCommas = (number: number, prefix = ','): string => {
    if (number) {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, prefix)
    }
    return '0'
  }

  const formatLocalDateTime = (value: string | Date | dayjs.Dayjs | undefined, format: string): string | null => {
    return value ? dayjs(value).format(format) : null
  }

  const isIOS = (): boolean => {
    const ua = navigator.userAgent
    return /iPhone|iPad|iPod/i.test(ua)
  }
  const scrollToTop = (): void => {
    window.requestAnimationFrame(() => window.scrollTo(0, 0))
  }

  const formatAmountUnit = (str: string | number, divider: number = 1000): string => {
    if (str) {
      const amount = parseFloat((Number(str) / divider).toString())
      return amount.toLocaleString('en-US')
    }
    return '0'
  }

  const formatDataNumber = (number: number, base = 1000): number => {
    const data = Math.round(number * base) / base
    return data
  }

  const formatAmountUnitEvent = (str: string): string => {
    const amount = Math.round(Number(str))
    return amount.toLocaleString('en-US')
  }

  const formatFixNumber = (num: number, places = 0) => Number(num).toFixed(places)

  const downloadImage = (idHtml: string, classImg: string, classTextOnImg: string): void => {
    const element = document.getElementById(idHtml)
    if (!element) {
      return
    }
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = 250
    canvas.height = 300
    htmlToCanvas(classImg, classTextOnImg, element, context, canvas.width, canvas.height).then(() => {
      const link = document.createElement('a')
      link.href = canvas.toDataURL('image/png')
      link.download = 'qr-code.png'
      link.click()
    })
  }
  const htmlToCanvas = async (
    classImg: string,
    classTextOnImg: string,
    element: any,
    context: any,
    width: number,
    height: number
  ): Promise<void> => {
    context.fillStyle = '#fff'
    context.fillRect(0, 0, width, height)
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = element.querySelector(classImg).src
    const text = element.querySelector(classTextOnImg)?.innerHTML || ''
    await new Promise<void>((resolve, reject) => {
      img.onload = () => {
        const imageHeight = text ? height - 50 : height
        context.drawImage(img, 0, 0, width, imageHeight)
        resolve()
      }
      img.onerror = reject
    })
    context.font = '12px Arial'
    context.fillStyle = '#000'
    context.textAlign = 'center'
    context.fillText(text, width / 2, height - 20)
  }

  return {
    provide: {
      normalizeDiacritics,
      updateQueryStringParameter,
      formatPhone,
      formatNumber,
      formatMoney,
      formatMarkToNumber,
      formatNumberWithCommas,
      formatLocalDateTime,
      formatAmountUnitEvent,
      scrollToTop,
      formatAmountUnit,
      isIOS,
      formatDataNumber,
      downloadImage,
      formatFixNumber
    }
  }
})
