import { Router } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
  ;(nuxtApp.$router as Router).options.scrollBehavior = (to, from) => {
    if (to.hash) {
      return {
        el: to.hash
      }
    }

    return { top: 0 }
  }
})
